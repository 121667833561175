<template>
  <div id="app" class="container">
    <table>
      <thead>
        <tr>
          <td>Title</td>
          <td>Dir</td>
          <td>No.</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(book, i) in bookList" :key="i">
          <td>{{book.name}}</td>
          <td>{{book.dir}}</td>
          <td>{{book.number}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "app",
  data: function() {
    return {
      loading: false,
      message: "Click me!",
      isVisible: true,
      userList: [],
      bookList: [],
      baseUrl:  `//${window.location.host}${window.env.apiUrl}`
    };
  },
  methods: {
    async queryServer() {
      const response = await fetch(this.baseUrl);
      const result = await response.json();
      this.message = result.message;
    },
    
    async getBooks() {
      const { data } = await axios.get(`${this.baseUrl}book`)
      this.bookList = data
    },

    // get user list
    async getUsers() {
      this.loading = true;
      const { data } = await axios.get(`${this.baseUrl}user`);

      if (data.code !== 0) {
        this.userList = [];
      } else {
        this.userList = data.data || [];
      }
      this.loading = false;
    },

    formCheck(data) {
      if (!data.name) {
        alert("Please input name");
        return false;
      }
      if (!data.email) {
        alert("Please input email");
        return false;
      }
      if (!data.site) {
        alert("Please input site");
        return false;
      }
      return true;
    },

    // add a user
    async addUser(formData) {
      if (!this.formCheck(formData)) {
        return;
      }
      this.loading = true;
      const { data } = await axios.post(`${this.baseUrl}user`, formData);
      if (data.code !== 0) {
        alert(data.message);
      } else {
        this.getUsers();
      }
      this.loading = false;
    },

    async deleteUser(name) {
      const { data } = await axios.delete(`${this.baseUrl}user/${name}`);
      if (data.code !== 0) {
        alert(data.message);
      } else {
        this.getUsers();
      }
    }
  },
  mounted() {
    // get user list after ui mounted
    this.getBooks();
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
